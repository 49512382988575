import BlockButton from '@/components/blocks/core/BlockButton'
import BlockButtons from '@/components/blocks/core/BlockButtons'
import BlockCode from '@/components/blocks/core/BlockCode'
import BlockColumns from '@/components/blocks/core/BlockColumns'
import BlockCover from '@/components/blocks/core/BlockCover'
import BlockEmbed from '@/components/blocks/core/BlockEmbed'
import BlockGravityForm from '@/components/blocks/core/BlockGravityForm'
import BlockHeadings from '@/components/blocks/core/BlockHeadings'
import BlockImage from '@/components/blocks/core/BlockImage'
import BlockImageGallery from '@/components/blocks/core/BlockImageGallery'
import BlockList from '@/components/blocks/core/BlockList'
import BlockMediaText from '@/components/blocks/core/BlockMediaText'
import BlockParagraph from '@/components/blocks/core/BlockParagraph'
import BlockPullQuote from '@/components/blocks/core/BlockPullQuote'
import BlockQuote from '@/components/blocks/core/BlockQuote'
import BlockSeparator from '@/components/blocks/core/BlockSeparator'
import BlockSpacer from '@/components/blocks/core/BlockSpacer'
import BlockTable from '@/components/blocks/core/BlockTable'
import Video from '@/components/blocks/core/BlockVideo'
import AllLecturers from '@/components/blocks/custom/AllLecturers'
import AllPosts from '@/components/blocks/custom/AllPosts'
import Banner from '@/components/blocks/custom/Banner'
import Calendar from '@/components/blocks/custom/Calendar'
import Contacts from '@/components/blocks/custom/Contacts'
import CourseContactPerson from '@/components/blocks/custom/CourseContactPerson'
import CourseDates from '@/components/blocks/custom/CourseDates'
import CourseDetails from '@/components/blocks/custom/CourseDetails'
import FullWidthImage from '@/components/blocks/custom/FullWidthImage'
import Images from '@/components/blocks/custom/Images'
import Intro from '@/components/blocks/custom/Intro'
import LatestPosts from '@/components/blocks/custom/LatestPosts'
import Lecturers from '@/components/blocks/custom/Lecturers'
import LinkList from '@/components/blocks/custom/LinkList'
import Locations from '@/components/blocks/custom/Locations'
import Numbers from '@/components/blocks/custom/Numbers'
import Partners from '@/components/blocks/custom/Partners'
import Principles from '@/components/blocks/custom/Principles'
import Quote from '@/components/blocks/custom/Quote'
import Section from '@/components/blocks/custom/Section'
import Teaser from '@/components/blocks/custom/Teaser'
import TeaserCards from '@/components/blocks/custom/TeaserCards'

import PropTypes from 'prop-types'

export default function displayBlock(block, index) {
  const {attributes, name, innerBlocks} = block
  // prettier-ignore
  switch (name) {
    /* -- CORE BLOCKS -- */
    case 'core/button':
      return <BlockButton {...attributes} key={index} />;

    case 'core/buttons':
      return <BlockButtons {...attributes} innerBlocks={innerBlocks} key={index} />;

    case 'core/code':
    case 'core/preformatted':
      return <BlockCode {...attributes} key={index} />;

    case 'core/columns':
      return <BlockColumns {...attributes} innerBlocks={innerBlocks} key={index} />;

    case 'core/cover':
      return <BlockCover {...attributes} innerBlocks={innerBlocks} key={index} />;

    case 'core/embed':
      return <BlockEmbed {...attributes} key={index} />;

    case 'core/gallery':
      return <BlockImageGallery {...attributes} key={index} />;

    case 'core/heading':
      return <BlockHeadings {...attributes} key={index} />;

    case 'core/image':
      return <BlockImage {...attributes} key={index} />;

    case 'core/list':
      return <BlockList  {...attributes} innerBlocks={innerBlocks} key={index} />;

    case 'core/media-text':
      return <BlockMediaText {...attributes} innerBlocks={innerBlocks} key={index} />;

    case 'core/paragraph':
      return <BlockParagraph {...attributes} key={index} />;

    case 'core/pullquote':
      return <BlockPullQuote {...attributes} key={index} />;

    case 'core/quote':
      return <BlockQuote {...attributes} key={index} />;

    case 'core/separator':
      return <BlockSeparator {...attributes} key={index} />;

    case 'core/spacer':
      return <BlockSpacer {...attributes} key={index} />;

    case 'core/table':
      return <BlockTable {...attributes} key={index} />;

    /* -- GRAVITY FORMS BLOCKS -- */
    case 'gravityforms/form':
      return <BlockGravityForm {...attributes} key={index} />;

    /* ACF */
    case 'acf/intro':
      return <Intro {...attributes} key={index} />;

    case 'acf/video':
      return <Video {...attributes} key={index} />;

    case 'acf/teaser':
      return <Teaser {...attributes} key={index} />;

    case 'acf/section':
      return <Section {...attributes} key={index} />;

    case 'acf/full-width-image':
      return <FullWidthImage {...attributes} key={index} />;

    // case 'acf/courses':
    //   return <Courses {...attributes} key={index} />;

    case 'acf/all-lecturers':
      return <AllLecturers {...attributes} key={index} />;

    case 'acf/all-team':
      return <AllLecturers {...attributes} key={index} />;

    case 'acf/course-contact-person':
      return <CourseContactPerson {...attributes} key={index} />;

    case 'acf/lecturers':
      return <Lecturers {...attributes} key={index} />;

    case 'acf/course-details':
      return <CourseDetails {...attributes} key={index} />;

    case 'acf/quote':
      return <Quote {...attributes} key={index} />;

    case 'acf/course-dates': 
      return <CourseDates {...attributes} key={index} />
    

    case 'acf/numbers':
      return <Numbers {...attributes} key={index} />;

    case 'acf/contacts':
      return <Contacts {...attributes} key={index} />;

    case 'acf/locations':
      return <Locations {...attributes} key={index} />;

    case 'acf/images':
      return <Images {...attributes} key={index} />;

    case 'acf/principles':
      return <Principles {...attributes} key={index} />;

    case 'acf/link-list':
      return <LinkList {...attributes} key={index} />;

    case 'acf/all-posts':
      return <AllPosts {...attributes} key={index} />

    case 'acf/latest-posts':
        return <LatestPosts {...attributes} key={index} />

    case 'acf/teaser-cards':
        return <TeaserCards {...attributes} key={index} />

    case 'acf/partners':
        return <Partners {...attributes} key={index} />

    case 'acf/banner':
          return <Banner {...attributes} key={index} />

    case 'acf/calendar':
          return <Calendar {...attributes} key={index} />;

    // default:
    //   return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
  }
}

displayBlock.propTypes = {
  block: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
